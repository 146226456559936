import SspContextProvider from './utils/SspProvider/SspContextProvider';
import TranslationProvider from './utils/TranslationProvider';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import Main from './Main';
import { TrackingProvider } from './utils/TrackingContext';

i18next.use(initReactI18next).init({
    resources: {},
    lng: 'de-DE',
    fallbackLng: 'de-DE',
    returnNull: false,
    interpolation: {
        escapeValue: false,
    },
    saveMissing: (import.meta.env.VITE_REACT_APP_CHECK_KEYS as string) === 'true',
    missingKeyHandler: (lng, key, fallbackValue) => {
        console.warn(lng, key, fallbackValue);
    },
});
function App() {
    const projectGeneral = '3f0baca75e6662cf0e0a8f834f2445b9';

    return (
        <SspContextProvider>
            <TrackingProvider>
                <TranslationProvider projectGeneral={projectGeneral}>
                    <Main />
                </TranslationProvider>
            </TrackingProvider>
        </SspContextProvider>
    );
}

export default App;
