import React from 'react';
import { useTracking } from '../../../utils/TrackingContext';

export default function QuickCheckIn(): JSX.Element {
    const { trackingAllowed } = useTracking();
    return (
        <div id='checkin'>
            <h1>SPAREN SIE ZEIT MIT UNSEREM QUICK CHECK-IN</h1>
            <p>Vorab online einchecken und mehr Zeit ins Entdecken stecken:</p>
            <p>
                Mit unserem Quick Check-in erhalten Sie Ihren QR-Code zur Anmeldung via E-Mail und verkürzen so Ihre
                Wartezeiten vor Ort.
            </p>
            <a
                href='https://www.autostadt.de/o/abholung'
                rel='noreferrer'
                target='_blank'
                onClick={
                    () =>
                        trackingAllowed &&
                        window._paq?.push(['trackEvent', 'Jump to external page', 'Click', 'External Page']) //matomo statistics tracking for following out link
                }
            >
                <p className='checkin-link'>
                    Jetzt online einchecken
                    <svg>
                        <use xlinkHref='#arrow-link' />
                    </svg>
                </p>
            </a>
        </div>
    );
}
