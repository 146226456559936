import React, { createContext, useContext, useState } from 'react';

type TrackingContextType = {
    trackingAllowed: boolean | null;
    setTrackingAllowed: (value: boolean) => void;
};

const TrackingContext = createContext<TrackingContextType | undefined>(undefined);

export const TrackingProvider = (props: { children: React.ReactNode }) => {
    const [trackingAllowed, setTrackingAllowed] = useState<boolean | null>(null);

    return (
        <TrackingContext.Provider value={{ trackingAllowed, setTrackingAllowed }}>
            {props.children}
        </TrackingContext.Provider>
    );
};

export const useTracking = () => {
    const context = useContext(TrackingContext);
    if (context === undefined) {
        throw new Error('useTracking must be used within a TrackingProvider');
    }
    return context;
};
