import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useTracking } from './TrackingContext';

declare global {
    interface Window {
        _paq?: Array<(string | number | boolean | object)[]>;
    }
}

const MatomoTracker = () => {
    const location = useLocation();
    const { trackingAllowed } = useTracking();
    const scriptLoaded = useRef(false);

    useEffect(() => {
        if (trackingAllowed && !scriptLoaded.current) {
            const _paq = (window._paq = window._paq || []);

            /* tracker methods like "setCustomDimension" should be called before "trackPageView" */

            _paq.push(['trackPageView']);

            _paq.push(['trackVisibleContentImpressions', true, 750]);

            _paq.push(['enableLinkTracking']);

            _paq.push(['setTrackerUrl', 'https://monit-qs.autostadt.de/matomo.php']);

            _paq.push(['setSiteId', '5']);

            _paq.push(['enableHeartBeatTimer', 15]);

            const script = document.createElement('script');
            script.async = true;
            script.src = 'https://monit-qs.autostadt.de/matomo.js';
            document.head.appendChild(script);

            scriptLoaded.current = true;
        }
    }, [trackingAllowed]);

    useEffect(() => {
        if (window._paq) {
            window._paq.push(['setCustomUrl', location.pathname + location.search]);
            window._paq.push(['trackPageView']);
        }
    }, [location]);

    return null;
};

export default MatomoTracker;
