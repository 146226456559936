import React, { useContext, useState } from 'react';
import './styles.scss';
import { setOverlay } from '../../utils/SspProvider/dispatchFunctions';
import SspProviderContext from '../../utils/SspProvider/context';
import SSPContentVO from 'sspcontentext/dist/model/SSPContentVO';
import i18next from 'i18next';
import { useTracking } from '../../utils/TrackingContext';

interface PreviewTileProps {
    tileType: string;
    button?: string;
    h5?: string;
    p?: string;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    img?: string;
    url?: string;
    funfact?: SSPContentVO;
}

function PreviewTile(props: PreviewTileProps) {
    const store = useContext(SspProviderContext);
    const { trackingAllowed } = useTracking();
    const [expand, setExpand] = useState(false);
    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        props.onClick?.(event);
        trackingAllowed && window._paq?.push(['trackEvent', 'Open Overlay', 'Click', `${props.button || props.h5}`]); //matomo statistics tracking for opening preview tile overlay
    };
    return (
        <>
            <div
                className={`preview-tile-${props.tileType === 'large' ? 'large' : props.tileType === 'medium' ? 'medium' : 'small'}`}
                onClick={handleClick}
            >
                <div className='highlight-img'>
                    {props.img && props.img !== '' && props.img !== undefined ? (
                        <img alt='preview tile image' src={props.img}></img>
                    ) : (
                        <div className='fallback'>
                            <svg>
                                <use xlinkHref='#vw-logo-large' />
                            </svg>
                        </div>
                    )}
                </div>
                <div className={`highlight-tile ${expand ? 'expand' : ''}`}>
                    {props.tileType === 'large' ? (
                        <button>
                            {props.button}
                            <svg>
                                <use xlinkHref='#chevron-right' />
                            </svg>
                        </button>
                    ) : (
                        <>
                            <h5>{props.h5}</h5>
                            <p>{props.p}</p>
                            {props.tileType === 'medium' && (
                                <button
                                    className='transparent-blue'
                                    onClick={() => {
                                        setOverlay(store, {
                                            title: i18next.t('FunAndFacts'),
                                            headline: props.funfact?.asModel.str('Name'),
                                            descriptionUpper: props.funfact?.asModel.str('DescriptionUpper'),
                                            overlayImages: props.funfact?.asModel.reflist('Assets'),
                                            descriptionLower: props.funfact?.asModel.str('DescriptionBottom'),
                                            multiSlideOverlay: props.funfact?.asModel.reflist('SubStoryItem'),
                                            link: props.funfact?.asModel.str('Url'),
                                        });
                                        trackingAllowed &&
                                            window._paq?.push(['trackEvent', 'Open Overlay', 'Click', 'Fun & Facts']);
                                    }}
                                >
                                    <p> {i18next.t('LearnMore')}</p>
                                </button>
                            )}
                        </>
                    )}
                </div>

                {props.tileType === 'medium' && (
                    <>
                        <div className={`shadow ${expand ? 'hide' : ''}`} />
                        <button onClick={() => setExpand(!expand)}>
                            <svg className={`${expand ? 'rotate' : ''}`}>
                                <use xlinkHref={'#chevron-narrow-right'} />{' '}
                            </svg>
                        </button>
                    </>
                )}
            </div>
        </>
    );
}

export default PreviewTile;
