import React, { useContext, useEffect, useRef, useState } from 'react';
import SspProviderContext from '../../../utils/SspProvider/context';
import SSPContentVO from 'sspcontentext/dist/model/SSPContentVO';
import { setOverlay } from '../../../utils/SspProvider/dispatchFunctions';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { EditorialContentProps } from '../../Page/interfaces';
import i18next from 'i18next';
import { useTracking } from '../../../utils/TrackingContext';

export default function Gallery(props: EditorialContentProps): JSX.Element {
    const store = useContext(SspProviderContext);
    const contentModel = store.state.content ? store.state.content.asModel : new SSPContentVO('').asModel;

    const { trackingAllowed } = useTracking();
    const [currentGalleryIndex, setCurrentGalleryIndex] = useState<number>(0);
    const [aveImagesLoaded, setAveImagesLoaded] = useState<boolean>(true);
    {
        /*todo setAveImagesLoaded*/
    }
    const sliderRef = useRef<Slider>(null);
    const galleryAssets = contentModel.reflist('header_assets');

    const gallery360Assets = contentModel
        .reflist('header_assets')
        .filter(asset => asset.asModel.isinStrlist('tags', 'abholer_gallery_360'));

    const indexChangeEventType = 'gallery-index-change';

    useEffect(() => {
        const handleIndexChange = (e: CustomEvent<{ index: number }>) => {
            setCurrentGalleryIndex(e.detail.index);
        };

        window.addEventListener(indexChangeEventType, handleIndexChange as EventListener);
        return () => {
            window.removeEventListener(indexChangeEventType, handleIndexChange as EventListener);
        };
    }, []);

    useEffect(() => {
        if (sliderRef.current) {
            sliderRef.current.slickGoTo(currentGalleryIndex);
        }
    }, [currentGalleryIndex]);

    return (
        <>
            {gallery360Assets.length > 0 ||
                (galleryAssets.length > 0 && (
                    <div id='gallery'>
                        <h2>{i18next.t('Gallery')}</h2>
                        {aveImagesLoaded || props.editorialContent ? (
                            <>
                                <Slider
                                    className='swipeable-view'
                                    ref={sliderRef}
                                    dots={true}
                                    infinite={false}
                                    speed={500}
                                    slidesToShow={2}
                                    slidesToScroll={1}
                                    variableWidth={true}
                                    initialSlide={currentGalleryIndex}
                                    afterChange={current => {
                                        setCurrentGalleryIndex(current);
                                    }}
                                >
                                    {galleryAssets.map((imageContent, index) => (
                                        <div
                                            key={`gallery- ${index}`}
                                            onClick={() => {
                                                setOverlay(store, {
                                                    galleryImages: galleryAssets,
                                                    imageIndex: index,
                                                    setImageIndexEventType: indexChangeEventType,
                                                });
                                                trackingAllowed &&
                                                    window._paq?.push([
                                                        'trackEvent',
                                                        'Open Overlay',
                                                        'Click',
                                                        'Gallery Images',
                                                    ]); //matomo statistics tracking for opening image gallery
                                            }}
                                        >
                                            <img
                                                className='gallery-img'
                                                src={imageContent.asAsset.url}
                                                alt={`gallery- ${index}`}
                                            />
                                        </div>
                                    ))}
                                </Slider>
                            </>
                        ) : (
                            <div className='images-blurred'>
                                <div />
                                <div />
                                <button className='blue'>Fahrzeugbilder laden</button>
                            </div>
                        )}
                    </div>
                ))}
        </>
    );
}
